<template>
  <div class="Header">
    <div :class="{'Header--opacity': isFullWhite, 'Header--opacityNoWhite': !isFullWhite}">
      <div class="NavBar NavBar--Menu">
        <div class="NavBar__Item NavBar__Item--Logo ">

          <img :src="theme.logoUrl" />
        </div>
        <div class="NavBar__Item NavBar__Item--by">
          <p>By Experience</p>
          <p>Lab</p>
        </div>
        <router-link :to="{ name: 'welcome', params: { origin } }" class="NavBar__Item NavBar__Item--Welcome" v-if="!isObservator">
          {{$t('welcome')}}
        </router-link>
        <router-link :to="{ name: 'activities', params: { origin } }" class="NavBar__Item NavBar__Item--Activities">
          {{$t('activities')}}
          <div v-if="communityStatus.moderatorUnseendComments > 0 && isClient">
            {{communityStatus.moderatorUnseendComments}}
          </div>
        </router-link>
        <router-link :to="{ name: 'members', params: { origin } }" class="NavBar__Item NavBar__Item--Members" v-if="isClient && !isObservator">
          {{$t('members')}}
        </router-link>
        <router-link  :to="{ name: communityInfo.redeemUrl != undefined ? communityInfo.redeemUrl : 'redeem', params: { origin } }" class="NavBar__Item NavBar__Item--Members" v-if="!communityInfo.hideGamification">
          <div v-if="isUser && !isObservator">{{$t('redeem')}} Labs<span>({{userProfile.points}})</span></div>
          <div v-if="!isUser && !isObservator">{{$t('redeem_header')}}</div>
        </router-link>
        <router-link :to="{ name: 'historical'}" class="NavBar__Item NavBar__Item--Members" v-if="isUser && !communityInfo.hideGamification">
          {{$t('labs_historical')}}
        </router-link>
        <router-link :to="{ name: 'profileClient'}" class="NavBar__Item NavBar__Item--Personalization Desktop--only" v-if="isCommunityMainAdmin || isCommunityAdmin">
          {{$t('navbar_personalization')}}
        </router-link>
      </div>
      <div class="NavBar NavBar--Status">
        <div class="NavBar--Status--Actions">
          <router-link :to="{ name: 'search' , params: { origin } }" class="NavBar__Item NavBar__Item--Members" v-if="!isUser && !isObservator">
            <i class="fas fa-search"></i>
          </router-link>
          <router-link :to="{ name: 'email' , params: { origin } }" class="NavBar__Item NavBar__Item--Members" v-if="isModerator && communityInfo.status != 'REVISION'">
            <i class="far fa-envelope"></i>
          </router-link>
          <div class="NavBar__Item NavBar__Item--Chat" :class="{'NavBar__Item--Active': $router.currentRoute.name == 'chat'}" v-if="isUser">
            <div class="Chat__ButtonArea" @click="openChatPanel" v-if="!isChatOpen"></div>
            <div class="Chat__ButtonArea" @click="closeChatPanel" v-if="isChatOpen"></div>
            <i class="Icon fa fa-comments" />
            <Badge v-if="Number(communityTotalNotSeen)" :count="''+communityTotalNotSeen+''" />
            <popup-panel :show="isChatOpen" @clicked-outside="closeChatPanel">
              <Chat
                v-on:send-message="handleSendMessage"
                v-on:enlarge-pic="enlargePic( $event )"
                :chatMessages="communityChatMessages"
                :photoUploadUrl="imageUploadUrl"
                ref="chat"
              ></Chat>
            </popup-panel>
          </div>
          <router-link :to="{ name: 'chat', params: { origin } }" class="NavBar__Item NavBar__Item--Chat" v-if="!isUser">
            <i class="Icon fa fa-comments"/>
            <Badge v-if="communityTotalNotSeen" :count="communityTotalNotSeen" />
          </router-link>
          <div class="NavBar__Item NavBar__Item--Notifications" v-if="!isObservator">
            <div class="Notifications__ButtonArea" @click="openNotificationsPanel" v-if="!showNotifications"></div>
            <div class="Notifications__ButtonArea" @click="closeNotificationsPanel" v-if="showNotifications"></div>
            <i class="Icon fa fa-bell" />
            <Badge v-if="unseenNotifCount" :count="unseenNotifCount" />
            <popup-panel :show="showNotifications" @clicked-outside="closeNotificationsPanel">
              <notifications-panel
                :data="notifications"
                @close-panel="closeNotificationsPanel"
                v-on:clear-all-notifications="clearAllNotifications = true"
              ></notifications-panel>
            </popup-panel>
          </div>
        </div>
        <div class="NavBar--WhiteLine">

        </div>
        <router-link disabled="isObservator" :to="{ name: isClient ? 'profileClient' : 'profile', params: { origin } }" class="NavBar__Item NavBar__Item--User">
          <div class="UserInfo">
            <span class="UserName">{{ userProfile.nickname }}</span>
            <span class="UserRole UserRole--Moderator" v-if="isCommunityAdmin && !isCommunityMainAdmin">{{$t('role_admin')}}</span>
            <span class="UserRole UserRole--Moderator" v-if="isCommunityMainAdmin">{{$t('role_mainAdmin')}}</span>
            <span class="UserRole UserRole--Moderator" v-if="isModerator && !isCommunityAdmin">{{$t('role_moderator')}}</span>
            <span class="UserRole UserRole--Observator" v-if="isObservator">{{$t('role_observator')}}</span>
            <span class="UserRole UserRole--User" v-if="isUser">{{$t('role_user')}}</span>
          </div>
          <Avatar class="UserAvatar" :radius="true" :src="theme.moderatorLogo && isModerator ? theme.moderatorLogo : userProfile.image" />
        </router-link>
        <div class="ActionsMenu" ref="actionsMenu">
          <i class="fas fa-ellipsis-v" v-on:click="showActionsMenu = !showActionsMenu"></i>
          <transition name="list">
            <div class="ActionsMenu--Menu" v-if="showActionsMenu">
              <div v-on:click="handleLogout()"> <i class="fas fa-sign-out-alt"></i>Salir</div>
              <div v-on:click="handleRemoveAccount()" v-if="isUser"> <i class="far fa-trash-alt"></i>Borrar cuenta</div>
            </div>
          </transition>
        </div>
      </div>
      <div class="mobileMenu">
        <div class="mobileMenu--bars" v-on:click="showMobileMenu = !showMobileMenu">
          <input type="checkbox" :checked="showMobileMenu"/>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
    <div class="mobileMenu--Nav" :class="{'show':showMobileMenu}" v-on:click="showMobileMenu = false">
      <div class="mobileMenu--Status">
        <div class="mobileMenu--Status--Actions">
          <router-link :to="{ name: 'search' , params: { origin } }" v-if="!isUser">
            <i class="fas fa-search"></i>
          </router-link>
          <router-link class="mobileMenu--Chat" :to="{ name: 'chatMember', params: { origin } }" v-if="isUser">
            <i @click="openMobileChatPanel"  v-if="!mobileChat" class="Icon fa fa-comments"></i>
            <i @click="closeMobileChatPanel"  v-if="mobileChat" class="Icon fa fa-comments"></i>
            <Badge v-if="communityTotalNotSeen" :count="communityTotalNotSeen" />
          </router-link>
          <router-link class="mobileMenu--Chat" :to="{ name: 'chat', params: { origin } }" v-if="!isUser">
            <i class="Icon fa fa-comments"/>
            <Badge v-if="communityTotalNotSeen" :count="communityTotalNotSeen" />
          </router-link>
          <router-link :to="{ name: 'email' , params: { origin } }" v-if="isModerator && communityInfo.status != 'REVISION'">
            <i class="far fa-envelope"></i>
          </router-link>
          <router-link class="mobileMenu--Notifications" :to="{ name: 'notifications', params: { origin } }">
            <i class="Icon fa fa-bell"></i>
            <Badge v-if="unseenNotifCount" :count="unseenNotifCount" />
          </router-link>
        </div>
        <div class="mobileMenu--Status--User">
          <router-link :to="{ name: 'profile', params: { origin } }">
            <div class="UserInfo">
              <span class="UserName">{{ userProfile.nickname }}</span>
              <span class="UserRole UserRole--Moderator" v-if="isCommunityAdmin && !isCommunityMainAdmin">{{$t('role_admin')}}</span>
              <span class="UserRole UserRole--Moderator" v-if="isCommunityMainAdmin">{{$t('role_mainAdmin')}}</span>
              <span class="UserRole UserRole--Moderator" v-if="isModerator && !isCommunityAdmin">{{$t('role_moderator')}}</span>
              <span class="UserRole UserRole--Observator" v-if="isObservator">{{$t('role_observator')}}</span>
              <span class="UserRole UserRole--User" v-if="isUser">{{$t('role_user')}}</span>
            </div>
            <Avatar class="mobileMenu--UserAvatar" :radius="true" :src="theme.moderatorLogo && isModerator ? theme.moderatorLogo : userProfile.image" />
          </router-link>
        </div>
      </div>
      <div class="mobileMenu--Menu">
        <router-link :to="{ name: 'welcome', params: { origin } }" >
          {{$t('welcome')}}
        </router-link>
        <router-link :to="{ name: 'activities', params: { origin } }">
          {{$t('activities')}}
          <div v-if="communityStatus.moderatorUnseendComments > 0 && isClient">
            {{communityStatus.moderatorUnseendComments}}
          </div>
        </router-link>
        <router-link :to="{ name: 'members', params: { origin } }" v-if="isClient">
          {{$t('members')}} <span>({{ communityMembersCount }})</span>
        </router-link>
        <router-link  :to="{ name: communityInfo.redeemUrl != undefined ? communityInfo.redeemUrl : 'redeem', params: { origin } }" v-if="!communityInfo.hideGamification && (isUser || isCommunityAdmin)">
          <div v-if="isUser">{{$t('redeem')}} Labs<span>({{userProfile.points}})</span></div>
          <div v-if="!isUser">{{$t('redeem_info')}}</div>
        </router-link>
        <router-link :to="{ name: 'redeemReport' , params: { origin } }" v-if="!communityInfo.hideGamification && !isUser">
          {{$t('redeem_management')}}
        </router-link>
        <router-link :to="{ name: 'historical'}" v-if="isUser && !communityInfo.hideGamification">
          {{$t('labs_historical')}}
        </router-link>
      </div>
      <div class="mobileMenu--Separator">
        
      </div>
      <div class="mobileMenu--Actions">
        <router-link :to="{ name: 'profile', params: { origin } }">Tu perfíl</router-link>
        <router-link :to="{ name: 'moderator-admin'}" class="Mobile--only" v-if="isCommunityMainAdmin">
          {{$t('navbar_moderator_admin')}}
        </router-link>
        <router-link :to="{ name: 'profileClient'}" class="Mobile--only" v-if="isCommunityMainAdmin || isCommunityAdmin">
          {{$t('navbar_personalization')}}
        </router-link>
        <a v-on:click="handleLogout()">Salir</a>
        <a v-on:click="handleRemoveAccount()" v-if="isUser"> <i class="far fa-trash-alt"></i> Borrar cuenta</a>
      </div>
    </div>
    <popup-panel class="mobile-chat-container" :show="mobileChat" @clicked-outside="closeMobileChatPanel">
              <Chat class="mobile-chat"
                v-on:send-message="handleSendMessage"
                v-on:enlarge-pic="enlargePic( $event )"
                :chatMessages="communityChatMessages"
                :photoUploadUrl="imageUploadUrl"
                ref="chat"
              ></Chat>
            </popup-panel>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { onClickOutside } from '@vueuse/core'
import { defineComponent, ref } from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import Avatar from '~/components/Avatar';
import Badge from '~/components/Badge';
import Modal from '~/components/Modal';
import PopupPanel from '@/components/PopupPanel.vue';
import NotificationsPanel from '@/components/NotificationsPanel.vue';
import Chat from '@/components/ChatEL';
import {
  NOTIFICATIONS_CLEAR_ID,
  NOTIFICATIONS_FETCH_STATE,
  NOTIFICATIONS_CLEAR_POPUP_ID
} from '@/store/notifications/notifications.module';
import {
  USER_FETCH_PROFILE,
  USER_AUTH_LOGOUT,
  USER_REMOVE_ACCOUNT,
  USER_CHANGE_NICKNAME,
  USER_GET_NICKNAME_AVAILABILITY
} from '@/store/user.module';
import {COMMUNITY_FETCH_MEMBERS,COMMUNITY_FETCH_ONLINE_USERS} from '@/store/community/members.module';
import {
  COMMUNITY_FETCH_CHAT_MESSAGES,
  COMMUNITY_ADD_CHAT_MESSAGE,
  COMMUNITY_SEE_CHAT_MESSAGES,
  COMMUNITY_FETCH_CHAT_LIST,
  COMMUNITY_SET_CHAT_USER_UNSEEN_UPDATE,
} from '@/store/community/chat.module';
import store from '@/store';
import Pusher from 'pusher-js';

export default {
  name: 'Header',

  components: {
    Avatar,
    Modal,
    Badge,
    PopupPanel,
    NotificationsPanel,
    Chat,
    Pusher,
  },
  watch: {
    mobileChat(newValue) {
      if (newValue) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    },
   notifications(newNotifications) {
    console.log(newNotifications)
    const specificType = 'CUSTOM_TESTER_COMMUNITY_NOTIFICATION'; // Cambia esto al tipo específico que desees
    const filteredNotifications = newNotifications.filter(notification => notification.type === specificType && notification.seen === false && notification.popupSeen === false);
    const notificationIds = filteredNotifications.map(notification => notification.id);

    if (filteredNotifications.length > 0) {
      // Crear un mensaje para todas las notificaciones filtradas
      const notificationMessages = filteredNotifications.map(notification => 
        `<strong>${notification.title.translated}</strong>: ${notification.text.translated}`
      ).join('<br><br>');

      // Mostrar un único popup con todas las notificaciones del tipo específico
      Swal.fire({
        title: this.$t("custom_notification_new_popup"),
        html: notificationMessages,
        icon: 'info',
        customClass: {
          popup: 'swal-customNotification'
        }
      }).then(() => {
      this.$store.dispatch('NOTIFICATIONS_CLEAR_POPUP_ID', { ids: notificationIds });
    });
    }
  }
  },

  computed: {
    ...mapGetters([
      'origin',
      'userProfile',
      'isModerator',
      'isObservator',
      'isCommunityMainAdmin',
      'isCommunityAdmin',
      'isUser',
      'isClient',
      'communityId',
      'communityMemberIds',
      'communityMembersCount',
      'communityTotalNotSeen',
      'communitySelectedChat',
      'communityChatMessages',
      'notifications',
      'unseenNotifCount',
      'theme',
      'enlargedPic',
      'communityInfo',
      'communityStatus',
    ]),

    imageUploadUrl ( ) {
      return process.env.VUE_APP_API_URL + '/experienceLabs/file'
    },

    isFullWhite() {
      if (this.theme.mainColor == '#ffffff') {
        return true
      } else {
        document.documentElement.style.setProperty('--header-color-text', '#ffffff');
        return false
      }
    },
    isMobileChatActive(){
      return this.mobileChat = !this.mobileChat
    }
  },

  data ( ) {
    return {
      showNotifications: false,
      isChatOpen:false,
      modalContent: null,
      showMobileMenu:false,
      clearAllNotifications:false,
      mobileChat:false,
      newNickname: null
    }
  },

  methods: {
    ...mapMutations({
      enlargePic: 'enlargePic',
      addChatNotification:'addChatNotification',
      addChatMessage: 'addChatMessage',
      addChatMessageObs: 'addChatMessageObs',
      removeNotSeenCount: 'removeNotSeenCount',
    }),

    openNotificationsPanel ( ) {
      if ( !this.showNotifications ) {
        this.showNotifications = true;
        this.fetchNotifications( );
      } else {
        this.closeNotificationsPanel()
      }
    },

    closeNotificationsPanel ( ) {
      this.showNotifications = false;
      this.fetchNotifications( );
    },

    fetchNotifications ( ) {
      if ( this.showNotifications || this.clearAllNotifications) {
        const last5 = this.notifications ? this.notifications.filter( ( d, i ) => i < 5 ).map( d => d.id ) : [];
        const allIds = this.notifications ? this.notifications.map( d => d.id ) : [];
        if (this.clearAllNotifications) {
          this.$store.dispatch( NOTIFICATIONS_CLEAR_ID, { ids: allIds, isClient: this.isClient, hideSpinner: true } )
          this.clearAllNotifications = false;
        } else {
          this.$store.dispatch( NOTIFICATIONS_CLEAR_ID, { ids: last5, isClient: this.isClient, hideSpinner: false } )
        }
      }
    },

    fetchNotificationsBefore ( ) {
      const last5 = this.notifications
        ? this.notifications.filter( ( d, i ) => i < 5 ).map( d => d.id )
        : [];
      this.$store.dispatch( NOTIFICATIONS_CLEAR_ID, { ids: last5, isClient: this.isClient, hideSpinner: false } )
    },

    preventClick ( e ) {
      return;
    },
    closeMenu(ev){
      this.showActionsMenu = false
    },
    openChatPanel ( ) {
      if ( !this.isChatOpen ) {
        this.isChatOpen = true;
        this.seeChatMessages()
        this.removeNotSeenCount()
      } else {
        this.closeChatPanel()
      }
    },

    closeChatPanel ( ) {
      this.isChatOpen = false;
    },
    openMobileChatPanel ( ) {
      if ( !this.mobileChat ) {
        this.mobileChat = true;
        this.seeChatMessages()
        this.removeNotSeenCount()
      } else {
        this.closeMobileChatPanel()
      }
    },

    closeMobileChatPanel ( ) {
      this.mobileChat = false;
    },

    seeChatMessages: async function(){
      await store.dispatch(COMMUNITY_SEE_CHAT_MESSAGES, {
        userId:this.communitySelectedChat,
        messageIds:_.map(this.communityChatMessages, function (o, i) { return o.identifier })
      })
    },

    chatConnection: async function(){
      Pusher.logToConsole = false;
      let pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
        cluster: 'eu',
        forceTLS: true,
      });

      if (this.isClient) {
        for(let id of this.communityMemberIds){
          pusher.subscribe('chat-' + this.communityId + '-' + id)
        }
        pusher.subscribe('chat-' + this.communityId + '-observer')
      }
      else pusher.subscribe('chat-' + this.communityId + '-' + this.userProfile.id)

      pusher.bind('chat-message', this.isClient ? this.handleReceivedMessageClient : this.handleReceivedMessageUser)
    },

    handleReceivedMessageClient: async function(m){
      if(m.chatName.includes("observer")){
        if(this.$route.name == 'activities.videochat') return
        this.addChatMessageObs(m)
        return
      }
      if (m.isModerator) {
        this.addChatMessage(m)
      }
      if (!m.isModerator) {
        if (this.$route.name != "chat") {
          await store.dispatch(COMMUNITY_SET_CHAT_USER_UNSEEN_UPDATE, {
            totalNotSeen:1,
            delete: false,
            update: true,
          })
        }
        else if (m.chatName != "chat-"+ this.communityId +"-"+ this.communitySelectedChat) {
          this.addChatNotification(m.chatName.replace('-','').replace('-','').replace('chat','').replace(this.communityId,''))
          await store.dispatch(COMMUNITY_SET_CHAT_USER_UNSEEN_UPDATE, {
            totalNotSeen:1,
            delete: false,
            update: true,
          })
        }

        else if (m.chatName == "chat-"+ this.communityId +"-"+ this.communitySelectedChat) {
          this.addChatMessage(m)
          this.seeChatMessages()
        }
      }
    },

    handleReceivedMessageUser: async function(m){
      this.addChatMessage(m)
      if (this.isChatOpen || this.$route.name == "chatMember") {
        this.seeChatMessages()
      }
      else{
        await store.dispatch(COMMUNITY_SET_CHAT_USER_UNSEEN_UPDATE, {
          totalNotSeen:1,
          delete: false,
          update: true,
        })
      }
    },

    handleSendMessage: async function(e){
      this.$emit('send-message',e)
      await store.dispatch(COMMUNITY_ADD_CHAT_MESSAGE, {
        message: e.message,
        image  : e.image,
        file   : e.file,
        video  : e.video,
        userId:this.communitySelectedChat,
      })
    },

    fetchChatMessages: async function(){
      store.dispatch(COMMUNITY_FETCH_CHAT_MESSAGES,{
        userId:this.communitySelectedChat
      })
    },
    async fetchOnlineUsers(){
      await store.dispatch(COMMUNITY_FETCH_ONLINE_USERS)
    },
    handleLogout() {
      let isClient = this.isClient
      store.dispatch( USER_AUTH_LOGOUT );

      if (isClient) this.$router.push( { name: 'clientLogin', params: { origin: this.$route.params.origin, }, } )
      else this.$router.push( { name: 'login', params: { origin: this.$route.params.origin, }, } );
    },
    handleRemoveAccount(){
      var vm = this;
      Swal.fire({
        title: vm.$t('msg_confirmation_delete_account'),
        text: vm.$t('msg_confirmation'),
        icon: "warning",
        cancelButtonText: vm.$t('action_cancel'),
        confirmButtonText: vm.$t('msg_agree'),
        showCancelButton: true,
        customClass:{
          popup:'swal-customWarning'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch(USER_REMOVE_ACCOUNT).then(() => {
            Swal.fire({
              title:"Cuenta eliminada",
              icon:"success",
              customClass:{popup:"swal-customSuccess"},
            }).then((result) => {
              if (result.isConfirmed || result.dismiss === Swal.DismissReason.overlay || 
                      result.dismiss === Swal.DismissReason.esc || result.dismiss === Swal.DismissReason.backdrop) {
                window.location.reload().then(() => {
                  vm.$router.push( { name: 'login', params: { origin: vm.$route.params.origin, }, } );
                });
              }
            });
          });
        }
      })
    },
  },

  async mounted(){
    setInterval( (  ) => {
      this.fetchOnlineUsers()
    }, 15000 );
    await store.dispatch(USER_FETCH_PROFILE,{communityName: this.$route.params.origin})
    this.chatConnection()
    if (this.isUser) this.fetchChatMessages()

    this.fetchNotificationsBefore()
    if (this.isUser && this.userProfile.nickname.includes("-"+this.userProfile.id)) {
  Swal.fire({
    text: this.$t('new_nickname_text'),
    input: 'text',
    inputAttributes: {
      autocapitalize: 'off',
      placeholder: this.$t('profile_data_nickname')
    },
    showCancelButton: false,
    confirmButtonText: this.$t('action_save'),
    allowOutsideClick: false,
    allowEscapeKey: false,
    didOpen: () => {
      const input = Swal.getInput();
      input.addEventListener('input', () => {
        input.value = input.value.trim();
        if (!input.value) {
          input.setCustomValidity(this.$t('error_new_nickname_empty'));
        } else {
          input.setCustomValidity('');
        }
      });
    },
    preConfirm: (value) => {
      value = value.trim();
      if (!value) {
        Swal.showValidationMessage(this.$t('error_new_nickname_empty'));
        return false;
      }
      this.newNickname = value;
    }
  }).then((result) => {
    if (result.isConfirmed) {
      store.dispatch(USER_GET_NICKNAME_AVAILABILITY, {
        nickname: this.newNickname
      })
      .then((data) => {
        if(!data.availability){
          Swal.fire({
            title: this.$t('error_already_user_exists'),
            icon: 'error'
          });
        } else {
          const data = {
            newNickname: this.newNickname
          }
          store.dispatch(USER_CHANGE_NICKNAME, data)
          .then((data) => {
            Swal.fire({
              title: this.$t('modators_admin_success_text'),
              icon: 'success'
            }).then((result) => {
              if (result.isConfirmed || result.dismiss === Swal.DismissReason.overlay || result.dismiss === Swal.DismissReason.esc || 
                  result.dismiss === Swal.DismissReason.backdrop) {
                window.location.reload();
              }
            });
          });
        }
      });
    }
  });
}
  },

  setup() {

  const actionsMenu = ref(null)
  const showActionsMenu = ref(false)
  onClickOutside(actionsMenu, (event) => showActionsMenu.value = false)

  return { actionsMenu, showActionsMenu }
}
}
</script>

<style scoped lang="scss">

:root {
  --header-color-text: #000000;
}

.list-enter,
.list-leave-to {
  visibility: hidden;
  width: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
}
.ActionsMenu{
  color: var(--header-color-text);
  margin: auto 10px auto 10px;
  cursor: pointer;
  position: relative;
  &--Menu{
    position: absolute;
    right: calc(100% - 16px);
    color: #3e3e3e;
    background: #e4e4e4;
    width: 155px;
    top: 29px;
    &::after{
      content: "";
      position: absolute;
      top: -16px;
      right: 4.5px;
      border-width: 8px;
      border-style: solid;
      border-color: transparent transparent #e4e4e4 transparent;
    }
    div{
      display: flex;
      align-items: center;
      padding: 10px;
      border-bottom: 1px solid lightgray;
      i{
        margin-right: 5px;
      }
    }
  }
}
.Header {
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9998;
  background:url('#/assets/img/waves-bg.png'),var(--primary-color);
  &--opacity{
    background: rgba(0,0,0,0);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
  }
  &--opacityNoWhite{
    background: rgba(0,0,0,0.5);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
  }
}

.NavBar {
  display: flex;
  flex-direction: row;
  &__Item {
    padding: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    position: relative;

    &--Logo {
      padding: 5px 10px;
      img {
        height: 55px;
      }
    }
  }
  &--WhiteLine{
    width:1px;
    height: 37px;
    background: var(--header-color-text);
    margin: auto 5px auto 5px;
  }
}

.NavBar--Menu {
  .NavBar__Item {
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;

    &:hover:not(.NavBar__Item--Logo) {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.22), rgba(0, 0, 0, 0.22)),var(--primary-color);
    }

    &.router-link-active {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.22), rgba(0, 0, 0, 0.22)),var(--primary-color);
    }

    &--by{
      font-family: Roboto Condensed;
      font-style: normal;
      text-transform: uppercase;
      font-size:10px;
      display:flex;
      flex-direction:column;
      align-items: flex-end;
      justify-content: center;
      margin: auto;
      height: 37px;
      border-right: 1px solid var(--header-color-text);
      margin-right: 15px;
      white-space: nowrap;
      line-height: 10px;
      &:hover{
        background: none!important;
      }
      p:nth-child(2){
        font-weight: bold;
        font-size: 12px;
      }
    }
  }

}

.NavBar--Status {
  .NavBar__Item {
    color: var(--header-color-text);
    position: relative;
    padding: 7px;
    i{
      font-size: 16px;
    }
    &--Chat,
    &--Notifications {
      &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.22), rgba(0, 0, 0, 0.22)),var(--primary-color);
      }

      .Badge {
        position: absolute;
        top: 21px;
        right: 0px;
        width: 13px;
        height: 13px;
        font-size: 7px;
        border: 0.5px solid #FFFFFF;
      }
    }
    &--Chat {
      .Chat__ButtonArea {
        background: transparent;
        position: absolute;
        z-index: 1000;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        cursor: pointer;
      }
    }

    &--Notifications {
      cursor: default;

      .Notifications__ButtonArea {
        background: transparent;
        position: absolute;
        z-index: 1000;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        cursor: pointer;
      }
    }

    &--User {
      display: flex;
      flex-direction: row;

      .UserAvatar {
        width: 33px;
        height: 33px;
        box-shadow: 0 0 2px #ddd;
      }

      .UserInfo {
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        max-width: 100px;
      }

      .UserName {
        font-weight: bold;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: Roboto Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        color: white;
      }

      .UserRole {
        font-family: Roboto Condensed;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
      }
    }

    &--Actions {
      border-left: none;
      display: none;
    }

    &--Active,
    &.router-link-active {
      color: var(--header-color-text);
    }
  }
  &--Actions{
    display: flex;
    justify-content: center;
  }
}

.NavBar__Item{
  color:var(--header-color-text);
  &--Activities{
    div{
      position: absolute;
      top: 10px;
      right: 8px;
      background-color: var(--primary-color);
      width: 22px;
      height: 22px;
      display: flex;
      border-radius: 50%;
      font-weight: bold;
      color: var(--header-color-text);
      justify-content: center;
      font-size: 10px;
      flex-direction: column;
      text-align: center;
      border: 2px solid;
      border-color: var(--header-color-text);
    }
  }
}

.mobileMenu{
  display: none;
  margin: auto 9px auto 0px;
  input{
    display: flex;
    width: 40px;
    height: 32px;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
  }
  span{
    display: flex;
    width: 29px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: #ffffff;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 5px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
            background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
            opacity 0.55s ease;
  }
  span:first-child{
    transform-origin: 0% 0%;
  }
  input:checked ~ span{
    opacity: 1;
    transform: rotate(135deg) translate(-17px, 0px);
  }
  input:checked ~ span:nth-last-child(3){
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }
  input:checked ~ span:nth-last-child(2){
    transform: rotate(45deg) translate(-5px, -3px);
  }
  &--Nav{
    display:none;
    flex-direction: column;
    color: white;
    height: calc(100vh - 60px);
    background: var(--primary-color);
  }
  &--Menu,&--Actions{
    display: flex;
    flex-direction: column;
    background:rgba(0, 0, 0, 0.5);
    padding: 25px 0 25px 58px;
    a{
      color:white;
      font-family: Roboto Condensed;
      font-style: normal;
      font-weight: normal;
      font-size: 23px;
      margin-bottom: 20px;
    }
  }
  &--Actions{
    // height: 100%;
  }
  &--Separator{
    opacity: 0.4;
    height: 1px;
    background: #FFFFFF;
    width: 100%;
  }
  &--Status{
    background: rgba(0,0,0,0.7);
    display: flex;
    justify-content: space-between;
    &--Actions{
      margin: 25px 0 25px 30px;
      a{
        color: white;
        font-size: 20px;
        margin-right: 20px;
      }
    }
    &--User {
      margin:15px;
      a{
        display: flex;
        flex-direction: row;
        color: white;
        align-items: center;
      }
      .UserInfo {
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        max-width: 100px;
      }

      .UserName {
        font-weight: bold;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: Roboto Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        color: white;
      }

      .UserRole {
        font-family: Roboto Condensed;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        opacity: 0.7;
      }
    }
  }
  &--UserAvatar{
    width: 50px;
    height: 50px;
    border: 2px solid #FFFFFF;
    box-shadow:none;
  }
  &--Chat,&--Notifications{
    position: relative;
    .Badge{
      position: absolute;
      top: 0px;
      right: 0px;
      width: 13px;
      height: 13px;
      font-size: 7px;
      border: 0.5px solid #FFFFFF;
    }
  }
}

@media (max-width: 767px) {
  .Header {
    height: 60px;
  }

  .NavBar__Item {
    &--by{
      border-right:none!important;
      font-size: 12px!important;
      line-height: 11px!important;
      p:nth-child(2){
        font-size: 14px!important;
      }
    }
    &--Welcome,
    &--Members,
    &--Activities,
    &--Chat,
    &--Notifications,
    &--User {
      display: none !important;
    }
  }
  .NavBar--Status--Actions{
    border-right: none;
  }
  .NavBar--WhiteLine{
    display: none;
  }
  .ActionsMenu{
    display: none;
  }
  .mobileMenu{
    display: block;
    &--Nav{
      &.show{
        display:flex!important;
      }
    }
  }
}

@media (max-width: 985px) {
  .NavBar--Menu {
    .NavBar__Item{
      font-size: 15px;
      padding: 10px;
      &--by{
        font-size:10px;
        margin-right: 10px;
      }
    }
  }
}
@media (max-width: 867px) {
  .NavBar--Menu {
    .NavBar__Item{
      font-size: 13px;
      padding: 8px;
      &--by{
        font-size:10px;
        margin-right: 8px;
      }
    }
  }
  .NavBar--Status{
    .NavBar__Item{
      padding: 5px;
    }
  }
}
@media (max-width: 600px) {
  .NavBar--Menu {
    .NavBar__Item{
      padding: 3px;
      &--by{
        margin-right: 3px;
      }
    }
  }
  .Desktop--only{
    display: none;
  }
}
@media (min-width: 600px) {

  .Mobile--only{
    display: none;
  }
}
</style>

<style lang="scss">
.Header{
  .PanelContainer{
    &:before,.PopupPanel{
      filter:none!important;
    }
    &:before{
      left: -28px;
    }
  }
}

.mobile-chat-container{
  top:100%;
}

.mobile-chat {
  width: 100vw;
  height: calc(100vh - 140px);
  display: flex;
  flex-direction: column;

}
.Chat{
  justify-content: space-between;
}
.Chat__Messages {
    padding: 1em;
    height: auto;
}

.no-scroll {
  height: 0;
  overflow: hidden;
}

.Chat {
  flex: 1; 
  display: flex;
  flex-direction: column;
}

.Chat__Messages {
  overflow-y: auto; 
}

.Chat__AddMedia,
.Chat__Controls {
  flex-shrink: 0; 
}
</style>
